<template>
  <div>
    <v-card flat class="pa-3 mt-2">
      <v-toolbar dense dark color="primary">
        <v-toolbar-title
        ><h4 class="font-weight-light">REMITTANCE (SEND OR RECEIVE) WITH GL EXPRESS</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-stepper v-model="e1" non-linear :key="is_reset">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">
              {{ step_one_title }}
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 2" step="2">
              {{ step_two_title }}
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3" :complete="e1 > 3"> Confirmation</v-stepper-step>
            <v-divider></v-divider>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="mb-12" color="grey lighten-1">
                <step1
                  v-on:data="on_select_sender"
                  :is_second_step="false"
                  :sender_id="0"
                  :type_of_transaction="''"
                ></step1>
              </v-card>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="
                      (sender_id > 0 && step_one_title === 'Send Money') ||
                      (Object.keys(receiver_data).length > 0 && step_one_title === 'Receive Money')
                    "
                  color="primary"
                  @click="(Object.keys(receiver_data).length  > 0 && step_one_title === 'Receive Money')?e1=3:e1++"
                >
                  Next
                </v-btn>
              </v-card-actions>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card class="mb-12" color="grey lighten-1">
                <step2
                  v-on:data="on_select_receiver"
                  :type_of_transaction="step_one_title"
                  :sender_id="sender_id"
                  :is_second_step="true"
                ></step2>
              </v-card>

              <v-card-actions>
                <v-btn color="warning" @click="e1--"> Back</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="sender_id > 0 && step_one_title === 'Send Money' && receiver_amount>99"
                  color="primary" @click="e1++">
                  Next
                </v-btn>
              </v-card-actions>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card class="mb-12" color="grey lighten-1">
                <confirmation :confirmation_data="confirmation_data"></confirmation>
              </v-card>
              <v-card-actions>
                <!-- alert -->
                <v-col cols="12" v-show="alert">
                  <v-alert color="warning" text class="mb-0">
                    <div class="d-flex align-start">
                      <v-icon color="warning">
                        {{ icons.mdiAlertOutline }}
                      </v-icon>

                      <div class="ms-3">
                        <p class="text-base font-weight-medium mb-1">
                          {{ alert_message }}
                        </p>
                      </div>
                    </div>
                  </v-alert>
                </v-col>
                <v-btn color="warning"
                       @click="(Object.keys(receiver_data).length  > 0 && step_one_title === 'Receive Money')?e1=1:e1--">
                  Back
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" v-if="!saving"
                       @click="(Object.keys(receiver_data).length  > 0 && step_one_title === 'Receive Money')?pera_kuha():pera_padala()">
                  Save & Print
                </v-btn>
                <v-progress-circular color="primary" indeterminate
                                     v-else></v-progress-circular>
              </v-card-actions>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import Step1 from '@/views/others/Step1'
  import Step2 from '@/views/others/Step1'
  import Confirmation from '@/views/others/Confirmation'
  import moment from "moment";

  const initialState = () => {
    return {
      saving: false,
      alert: false,
      alert_message: '',

      is_reset: false,
      e1: 1,
      step_one_title: 'Select Transaction',
      step_two_title: 'STEP 2',

      sender_picture: '',
      sender_id: 0,
      sender_account_no: '',
      sender_full_name: '',
      receiver_picture: '',
      receiver_id: 0,
      receiver_account_no: '',
      receiver_full_name: '',
      receiver_reference_no: '',
      receiver_code: '',
      receiver_amount: 0,
      receiver_amount_fee: 0,

      receiver_data: {},
    }
  }
  export default {
    components: {
      snackBarDialog,
      Step1,
      Step2,
      Confirmation,
    },
    setup() {
      return {
        confirmation_data: {},
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },
    data() {
      return initialState()
    },
    watch: {
      e1: function () {
        if (Object.keys(this.receiver_data).length > 0 && this.step_one_title === 'Receive Money') {
          this.confirmation_data = {
            sender_picture: this.receiver_picture,
            receiver_picture: this.sender_picture,

            receiver_id: this.receiver_data.receiver.id,
            sender_id: this.receiver_data.sender.id,
            sender_account_no: this.receiver_data.sender.account_no,
            receiver_account_no: this.receiver_data.receiver.account_no,
            sender_full_name: this.receiver_data.sender.last_name + ', ' + this.receiver_data.sender.first_name + ' ' + this.receiver_data.sender.middle_name,
            receiver_full_name: this.receiver_data.receiver.last_name + ', ' + this.receiver_data.receiver.first_name + ' ' + this.receiver_data.receiver.middle_name,
            receiver_reference_no: this.receiver_data.reference_no,
            receiver_amount: this.receiver_data.amount,
            receiver_amount_fee: this.receiver_data.fee,
            receiver_code: this.receiver_data.code,
          }
        } else {
          this.confirmation_data = {
            sender_picture: this.sender_picture,
            receiver_picture: this.receiver_picture,
            receiver_id: this.receiver_id,
            sender_id: this.sender_id,
            sender_account_no: this.sender_account_no,
            receiver_account_no: this.receiver_account_no,
            sender_full_name: this.sender_full_name,
            receiver_full_name: this.receiver_full_name,
            receiver_reference_no: this.receiver_reference_no,
            receiver_amount: this.receiver_amount,
            receiver_amount_fee: this.receiver_amount_fee,
            receiver_code: this.receiver_code,
          }
        }

        if (this.receiver_id > 0 && this.step_one_title === 'Send Money') {
          this.step_two_title = 'Receiver Name'
        }
        if (this.e1 === 1 && this.sender_id === 0) {
          this.step_one_title = 'Select Transaction'
        }
        if (this.e1 > 3) {
          this.reset()
        }
        if (this.e1 === 2 && this.receiver_id === 0 && this.step_one_title === 'Send Money') {
          this.step_two_title = 'Select Receiver'
        }
      },
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['month_start', 'month_end', 'month_of', 'user_id', 'branch_id', 'branch', 'branch_contact_no', 'branch_address']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('pera_padala', ['save_pera_padala', 'save_pera_kuha']),
      reset() {
        this.is_reset = true
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          Object.assign(this.$data, initialState())
        }, 100)
      },
      on_select_sender(value) {
        this.step_one_title = value.type_of_transaction
        this.step_two_title = value.next_step_title
        this.sender_id = value.customer_id

        this.sender_picture = value.picture
        this.sender_account_no = value.account_no
        this.sender_full_name = value.full_name

        this.receiver_picture = value.receiver_picture
        this.receiver_data = value.receiver_data
      },
      on_select_receiver(value) {
        this.receiver_id = value.customer_id
        this.receiver_picture = value.picture
        this.receiver_account_no = value.account_no
        this.receiver_full_name = value.full_name
        this.receiver_reference_no = value.receiver_reference_no
        this.receiver_code = value.receiver_code
        this.receiver_amount = value.receiver_amount
        this.receiver_amount_fee = value.receiver_amount_fee
      },
      pera_padala() {
        this.saving = true
        this.alert = false
        const data = new FormData()
        data.append('branch_id', this.branch_id)
        data.append('teller_id', this.user_id)
        data.append('sender_id', this.sender_id)
        data.append('receiver_id', this.receiver_id)
        data.append('reference_no', this.receiver_reference_no)
        data.append('code', this.receiver_code)
        data.append('amount', this.receiver_amount)
        data.append('fee', this.receiver_amount_fee)
        this.save_pera_padala(data)
          .then(response => {
            var color = 'success'
            if (response.status === 201) {
              color = 'error'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data[0].message,
            })
            this.print_receipt(response.data[0].datum)
          })
          .catch(error => {
            this.alert = true
            this.alert_message = error
            this.saving = false
          })
      },
      pera_kuha() {
        this.saving = true
        this.alert = false
        const data = new FormData()
        data.append('branch_id', this.branch_id)
        data.append('teller_id', this.user_id)
        data.append('id', this.receiver_data.id)
        data.append('claim_fee', 0)
        this.save_pera_kuha(data)
          .then(response => {
            var color = 'success'
            if (response.status === 201) {
              color = 'error'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data[0].message,
            })
            this.print_claim_receipt(response.data[0].datum)
          })
          .catch(error => {
            this.alert = true
            this.alert_message = error
            this.saving = false
          })
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      date_format(value) {
        return moment(value)
      },
      print_receipt(datum) {
        var title_array = []
        var head_array = []
        var data_array = []
        var foot_array = []
        var witdss = [60, 124]
        var witdssTi = [192]
        title_array.push(
          [
            {image: this.company_logo, width: 192, height: 60, style: 'logo'},
          ]
        )
        title_array.push(
          [
            {text: this.branch_address, style: 'subheader'},
          ]
        )
        title_array.push(
          [
            {
              text: 'TEL #: (082)333-1809 CONTACT NO.: ' + this.branch_contact_no,
              style: 'subheader',
            },
          ]
        )
        head_array.push(
          [
            {text: 'Branch: ' + this.branch, alignment: 'left'},
          ]
        )
        head_array.push(
          [
            {text: 'Ref No.: ' + this.receiver_reference_no, alignment: 'left',},
          ]
        )
        head_array.push(
          [
            {text: 'Code No.: ' + this.receiver_code, alignment: 'left',},
          ]
        )
        head_array.push(
          [
            {
              text: 'Claimable Amount: ' + this.formatPrice(this.receiver_amount),
              alignment: 'left',
            },
          ]
        )
        head_array.push(
          [
            {
              text: this.date_format(datum.updated_at).format('MMMM DD, YYYY HH:mm:ss'),
              alignment: 'left'
            },
          ]
        )
        data_array.push(
          [
            {text: 'Sender Name:', alignment: 'left'},
            {text: this.sender_full_name, alignment: 'left'},
          ]
        )
        data_array.push(
          [
            {text: 'Receiver Name:', alignment: 'left'},
            {text: this.receiver_full_name, alignment: 'left'},
          ]
        )
        data_array.push(
          [
            {text: 'Amount:', alignment: 'left'},
            {text: this.formatPrice(this.receiver_amount), alignment: 'left'},
          ]
        )
        data_array.push(
          [
            {text: 'Fee:', alignment: 'left'},
            {text: this.formatPrice(this.receiver_amount_fee), alignment: 'left'},
          ]
        )
        data_array.push(
          [
            {text: 'Total Paid:', alignment: 'left'},
            {
              text: this.formatPrice(parseFloat(this.receiver_amount_fee) + parseFloat(this.receiver_amount)),
              alignment: 'left'
            },
          ]
        )
        foot_array.push(
          [
            {text: 'WE BRING YOUR MONEY TO LIFE', alignment: 'center'},
          ]
        )
        foot_array.push(
          [
            {text: 'CLAIMABLE ONLY GL REMITTANCE NATIONWIDE', alignment: 'center'},
          ]
        )
        foot_array.push(
          [
            {text: 'Thank you and Come Again', alignment: 'center'},
          ]
        )
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs === undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageOrientation: 'portrait',
          pageMargins: 1,
          content: [
            {
              style: {
                margin: [0, 2, 0, 2],
                fontSize: 12,
              },
              table: {
                widths: witdssTi,
                body: title_array,
              },
              layout: {
                hLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.body.length) ? 1 : 0;
                },
                vLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.widths.length) ? 1 : 0;
                },
              },
            },
            {
              style: {
                margin: [0, 2, 0, 2],
                fontSize: 10,
              },
              table: {
                widths: witdssTi,
                body: head_array,
              },
              layout: {
                hLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.body.length) ? 1 : 0;
                },
                vLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.widths.length) ? 1 : 0;
                },
              },
            },
            {
              style: {
                margin: [0, 2, 0, 2],
                fontSize: 9,
              },
              table: {
                widths: witdss,
                body: data_array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex % 2 == 0) ? '#bae8cc' : null;
                },
                hLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.body.length) ? 1 : 0;
                },
                vLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.widths.length) ? 1 : 0;
                },
              },
            },
            {
              style: {
                margin: [0, 2, 0, 2],
                fontSize: 9,
              },
              table: {
                widths: witdssTi,
                body: foot_array,
              },
              layout: {
                hLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.body.length) ? 1 : 0;
                },
                vLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.widths.length) ? 1 : 0;
                },
              },
            },
          ],
          footer: {},
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 7,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 8,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 11,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        var win = window.open('', '', "width=1000,height=500");
        pdfMake.createPdf(docDefinition).print({}, win)
        this.e1++
      },
      print_claim_receipt(datum) {
        var title_array = []
        var head_array = []
        var data_array = []
        var foot_array = []
        var witdss = [60, 124]
        var witdssTi = [192]
        title_array.push(
          [
            {image: this.company_logo, width: 192, height: 60, style: 'logo'},
          ]
        )
        title_array.push(
          [
            {text: this.branch_address, style: 'subheader'},
          ]
        )
        title_array.push(
          [
            {
              text: 'TEL #: (082)333-1809 CONTACT NO.: ' + this.branch_contact_no,
              style: 'subheader',
            },
          ]
        )
        head_array.push(
          [
            {text: 'Branch: ' + this.branch, alignment: 'left'},
          ]
        )
        head_array.push(
          [
            {text: 'CLAIM', alignment: 'left',},
          ]
        )
        head_array.push(
          [
            {
              text: this.date_format(datum.created_at).format('MMMM DD, YYYY HH:mm:ss'),
              alignment: 'left'
            },
          ]
        )
        data_array.push(
          [
            {text: 'Sender Name:', alignment: 'left'},
            {
              text: this.receiver_data.sender.last_name + ', ' + this.receiver_data.sender.first_name + ' ' + this.receiver_data.sender.middle_name,
              alignment: 'left'
            },
          ]
        )
        data_array.push(
          [
            {text: 'Receiver Name:', alignment: 'left'},
            {
              text: this.receiver_data.receiver.last_name + ', ' + this.receiver_data.receiver.first_name + ' ' + this.receiver_data.receiver.middle_name,
              alignment: 'left'
            },
          ]
        )
        data_array.push(
          [
            {text: 'Claimed Amount:', alignment: 'left'},
            {text: this.formatPrice(this.receiver_data.amount), alignment: 'left'},
          ]
        )
        data_array.push(
          [
            {text: 'Fee:', alignment: 'left'},
            {text: 'NOT APPLICABLE', alignment: 'left'},
          ]
        )
        foot_array.push(
          [
            {text: 'WE BRING YOUR MONEY TO LIFE', alignment: 'center'},
          ]
        )
        foot_array.push(
          [
            {text: 'Thank you and Come Again', alignment: 'center'},
          ]
        )
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs === undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageOrientation: 'portrait',
          pageMargins: 1,
          content: [
            {
              style: {
                margin: [0, 2, 0, 2],
                fontSize: 12,
              },
              table: {
                widths: witdssTi,
                body: title_array,
              },
              layout: {
                hLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.body.length) ? 1 : 0;
                },
                vLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.widths.length) ? 1 : 0;
                },
              },
            },
            {
              style: {
                margin: [0, 2, 0, 2],
                fontSize: 10,
              },
              table: {
                widths: witdssTi,
                body: head_array,
              },
              layout: {
                hLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.body.length) ? 1 : 0;
                },
                vLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.widths.length) ? 1 : 0;
                },
              },
            },
            {
              style: {
                margin: [0, 2, 0, 2],
                fontSize: 9,
              },
              table: {
                widths: witdss,
                body: data_array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex % 2 == 0) ? '#bae8cc' : null;
                },
                hLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.body.length) ? 1 : 0;
                },
                vLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.widths.length) ? 1 : 0;
                },
              },
            },
            {
              style: {
                margin: [0, 2, 0, 2],
                fontSize: 9,
              },
              table: {
                widths: witdssTi,
                body: foot_array,
              },
              layout: {
                hLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.body.length) ? 1 : 0;
                },
                vLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.widths.length) ? 1 : 0;
                },
              },
            },
          ],
          footer: {},
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 7,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 8,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 11,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        var win = window.open('', '', "width=1000,height=500");
        pdfMake.createPdf(docDefinition).print({}, win)
        this.e1++
      },
    },
  }
</script>
