<template>
  <div class="mt-4">
    <v-card-text>
      <v-row v-if="!is_second_step">
        <v-col cols="12" md="4">
          <v-select
            v-model="option"
            dense
            outlined
            label="Type of Transaction"
            :items="['Send Money', 'Receive Money']"
            :rules="rules.combobox_rule"
            @change="reset"
          ></v-select>
        </v-col>
      </v-row>
      <v-row v-if="option != ''">
        <v-col md="9" cols="12" v-if="option === 'Send Money'">
          <v-row>
            <v-col md="3" cols="12">
              <v-text-field v-model="search_acc" label="Account No." dense
                            outlined></v-text-field>
            </v-col>
            <v-col md="3" cols="12">
              <v-text-field v-model="search_first_name" label="First Name" dense
                            outlined></v-text-field>
            </v-col>
            <v-col md="3" cols="12">
              <v-text-field v-model="search_middle_name" label="Middle Name" dense
                            outlined></v-text-field>
            </v-col>
            <v-col md="3" cols="12">
              <v-text-field v-model="search_last_name" label="Last Name" dense
                            outlined></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="9" cols="12" v-if="option === 'Receive Money'">
          <v-row>
            <v-col md="10" cols="12">
              <v-text-field v-model="search_reference_no" label="Reference No." dense
                            outlined></v-text-field>
            </v-col>
            <v-col md="2" cols="12">
              <v-text-field v-model="search_code" label="Code No." dense
                            outlined></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="3">
          <v-btn class="mr-2" v-if="!is_searching" color="primary"
                 @click="searching_search_items">
            <v-icon>
              {{ icons.mdiAccountSearchOutline }}
            </v-icon>
          </v-btn>
          <v-progress-circular color="primary" indeterminate v-else></v-progress-circular>
          <v-btn
            v-if="!is_searching && is_search_empty && option === 'Send Money'"
            color="info"
            @click="kyc_dialog = true"
          >
            KYC
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="search_items.length > 0">
        <v-col cols="12" md="12">
          <step1-table v-on:data="on_select_customer" :search_items="search_items"></step1-table>
        </v-col>
      </v-row>
      <v-row v-if="customer_id > 0">
        <v-col cols="12" md="4">
          <v-avatar rounded size="300" class="me-6">
            <v-img :src="cropped"></v-img>
          </v-avatar>
        </v-col>
        <v-col cols="12" md="8">
          <v-col md="4" cols="12">
            <v-text-field v-model="account_no" label="Account No." dense outlined
                          readonly></v-text-field>
          </v-col>
          <v-col md="12" cols="12">
            <v-text-field readonly v-model="full_name" label="Full Name" dense
                          outlined></v-text-field>
          </v-col>
          <v-col md="12" cols="12">
            <v-row v-if="is_second_step">
              <v-col md="6" cols="12">
                <v-text-field
                  readonly
                  v-model="generated_reference_no"
                  label="Reference No."
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col md="6" cols="12">
                <v-text-field
                  readonly
                  v-model="generated_code"
                  label="Code No."
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col md="4" cols="12">
                <v-text-field
                  v-model="amount_to_be_sent"
                  label="Amount"
                  dense
                  type="number"
                  min="0"
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>
              <v-col md="4" cols="12">
                <v-text-field
                  v-model="receiver_amount_fee"
                  label="FEE"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col md="4" cols="12">
                <v-text-field
                  v-model="total_pay"
                  label="Total"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="kyc_dialog" persistent max-width="80%">
      <k-y-c></k-y-c>
      <v-btn color="error" @click="kyc_dialog = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline, mdiAccountSearchOutline} from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import KYC from '@/views/register/NewClient'
  import Step1Table from './components/Customer_Table'

  const initialState = () => {
    return {
      cropped: require('@/assets/images/avatars/2.png'),
      cropped2: '',

      saving: false,
      alert: false,
      alert_message: '',

      search_reference_no: '',
      search_code: '',
      search_acc: '',
      search_first_name: '',
      search_middle_name: '',
      search_last_name: '',

      is_searching: false,
      is_search_empty: false,
      search_items: [],

      account_no: '',
      full_name: '',

      kyc_dialog: false,
      customer_id: 0,
      generated_reference_no: '',
      amount_to_be_sent: 0,
      receiver_amount_fee: 0,
      generated_code: 0,
      total_pay: 0,

      receiver_data: {},
    }
  }
  export default {
    props: {
      is_second_step: Boolean,
      type_of_transaction: String,
      sender_id: Number,
    },
    components: {
      snackBarDialog,
      KYC,
      Step1Table,
    },
    setup() {
      return {
        option: '',
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiAccountSearchOutline,
        },
      }
    },
    data() {
      return initialState()
    },
    watch: {
      customer_id: function () {
        this.change_type_of_transaction()
      },
      type_of_transaction: function () {
        this.option = this.type_of_transaction
        this.reference_no_generator()
      },
      amount_to_be_sent: function () {
        this.receiver_amount_fee = 0
        var amount = parseFloat(this.amount_to_be_sent)
        if (this.is_franchise) {
          if (amount >= 1 && amount <= 100) {
            this.receiver_amount_fee = 2
          } else if (amount >= 101 && amount <= 300) {
            this.receiver_amount_fee = 3
          } else if (amount >= 301 && amount <= 500) {
            this.receiver_amount_fee = 8
          } else if (amount >= 501 && amount <= 700) {
            this.receiver_amount_fee = 10
          } else if (amount >= 701 && amount <= 900) {
            this.receiver_amount_fee = 12
          } else if (amount >= 901 && amount <= 1000) {
            this.receiver_amount_fee = 15
          } else if (amount >= 1001 && amount <= 1500) {
            this.receiver_amount_fee = 20
          } else if (amount >= 1501 && amount <= 2000) {
            this.receiver_amount_fee = 30
          } else if (amount >= 2001 && amount <= 2500) {
            this.receiver_amount_fee = 40
          } else if (amount >= 2501 && amount <= 3000) {
            this.receiver_amount_fee = 50
          } else if (amount >= 3001 && amount <= 3500) {
            this.receiver_amount_fee = 60
          } else if (amount >= 3501 && amount <= 4000) {
            this.receiver_amount_fee = 70
          } else if (amount >= 4001 && amount <= 5000) {
            this.receiver_amount_fee = 90
          } else if (amount >= 5001 && amount <= 7000) {
            this.receiver_amount_fee = 115
          } else if (amount >= 7001 && amount <= 9500) {
            this.receiver_amount_fee = 125
          } else if (amount >= 9501 && amount <= 10000) {
            this.receiver_amount_fee = 140
          } else if (amount >= 10001 && amount <= 14000) {
            this.receiver_amount_fee = 210
          } else if (amount >= 14001 && amount <= 15000) {
            this.receiver_amount_fee = 220
          } else if (amount >= 15001 && amount <= 20000) {
            this.receiver_amount_fee = 250
          } else if (amount >= 20001 && amount <= 30000) {
            this.receiver_amount_fee = 290
          } else if (amount >= 30001 && amount <= 40000) {
            this.receiver_amount_fee = 320
          }
        } else {
          if (amount >= 1 && amount <= 100) {
            this.receiver_amount_fee = 2
          } else if (amount >= 101 && amount <= 300) {
            this.receiver_amount_fee = 3
          } else if (amount >= 301 && amount <= 500) {
            this.receiver_amount_fee = 8
          } else if (amount >= 501 && amount <= 700) {
            this.receiver_amount_fee = 10
          } else if (amount >= 701 && amount <= 900) {
            this.receiver_amount_fee = 12
          } else if (amount >= 901 && amount <= 1000) {
            this.receiver_amount_fee = 15
          } else if (amount >= 1001 && amount <= 1500) {
            this.receiver_amount_fee = 20
          } else if (amount >= 1501 && amount <= 2000) {
            this.receiver_amount_fee = 30
          } else if (amount >= 2001 && amount <= 2500) {
            this.receiver_amount_fee = 40
          } else if (amount >= 2501 && amount <= 3000) {
            this.receiver_amount_fee = 50
          } else if (amount >= 3001 && amount <= 3500) {
            this.receiver_amount_fee = 60
          } else if (amount >= 3501 && amount <= 4000) {
            this.receiver_amount_fee = 70
          } else if (amount >= 4001 && amount <= 5000) {
            this.receiver_amount_fee = 90
          } else if (amount >= 5001 && amount <= 7000) {
            this.receiver_amount_fee = 115
          } else if (amount >= 7001 && amount <= 9500) {
            this.receiver_amount_fee = 125
          } else if (amount >= 9501 && amount <= 10000) {
            this.receiver_amount_fee = 140
          } else if (amount >= 10001 && amount <= 14000) {
            this.receiver_amount_fee = 210
          } else if (amount >= 14001 && amount <= 15000) {
            this.receiver_amount_fee = 220
          } else if (amount >= 15001 && amount <= 20000) {
            this.receiver_amount_fee = 250
          } else if (amount >= 20001 && amount <= 30000) {
            this.receiver_amount_fee = 290
          } else if (amount >= 30001 && amount <= 40000) {
            this.receiver_amount_fee = 320
          }
        }
        this.total_pay = parseFloat(amount) + parseFloat(this.receiver_amount_fee)
        this.change_type_of_transaction()
      },
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
      ...mapGetters('authentication', ['month_start', 'month_end', 'month_of', 'user_id', 'branch_id', 'is_franchise']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('client_information', ['search_client']),
      ...mapActions('pera_padala', ['search_by_reference_no']),
      reset() {
        Object.assign(this.$data, initialState())
        this.change_type_of_transaction()
      },
      change_type_of_transaction() {
        this.$emit('data', {
          type_of_transaction: this.option,
          next_step_title: this.option === 'Send Money' ? 'Receiver Name' : 'Remittance Details',
          customer_id: this.customer_id,

          picture: this.cropped,
          receiver_picture: this.cropped2,
          account_no: this.account_no,
          full_name: this.full_name,
          receiver_reference_no: this.generated_reference_no,
          receiver_code: this.generated_code,
          receiver_amount: this.amount_to_be_sent,
          receiver_data: this.receiver_data,
          receiver_amount_fee: this.receiver_amount_fee,
        })
      },
      reference_no_generator() {
        var number = '0123456789'
        var characters = 'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789'
        var charactersLength = characters.length
        var result = ''
        var code = ''
        for (var i = 1; i <= 6; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength))
          code += number.charAt(Math.floor(Math.random() * number.length))
        }
        this.generated_reference_no = 'GL-' + result
        this.generated_code = code
      },
      searching_search_items() {
        this.customer_id = 0
        this.is_search_empty = false
        this.is_searching = true
        if (this.option === 'Send Money') {
          this.search_client({
            id: this.sender_id,
            account_no: this.search_acc,
            first_name: this.search_first_name,
            middle_name: this.search_middle_name,
            last_name: this.search_last_name,
          }).then(response => {
            this.is_searching = false
            this.search_items = response.data
            this.is_search_empty = this.search_items.length === 0
          })
        } else if (this.option === 'Receive Money') {
          this.search_by_reference_no({
            reference_no: this.search_reference_no,
            code: this.search_code,
          }).then(response => {
            this.is_searching = false
            this.search_items = response.data
            this.is_search_empty = this.search_items.length === 0
          })
        }
      },
      on_select_customer(value) {
        this.customer_id = value.id
        this.cropped = value.cropped
        this.cropped2 = value.cropped2
        this.account_no = value.account_no
        this.full_name = value.full_name
        this.receiver_data = value.data2
      },
    },
  }
</script>
