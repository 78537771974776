<template>
  <div class="mt-4">
    <v-card-text>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">SENDER INFO</h4></v-toolbar-title>
      </v-toolbar>
      <v-row>
        <v-col cols="12" md="4">
          <v-avatar rounded size="300" class="me-6">
            <v-img :src="confirmation_data.sender_picture"></v-img>
          </v-avatar>
        </v-col>
        <v-col cols="12" md="8">
          <v-col md="4" cols="12">
            <v-text-field
              v-model="confirmation_data.sender_account_no"
              label="Account No."
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>
          <v-col md="12" cols="12">
            <v-text-field
              readonly
              v-model="confirmation_data.sender_full_name"
              label="Full Name"
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-col>
      </v-row>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">RECEIVER INFO</h4></v-toolbar-title>
      </v-toolbar>
      <v-row>
        <v-col cols="12" md="4">
          <v-avatar rounded size="300" class="me-6">
            <v-img :src="confirmation_data.receiver_picture"></v-img>
          </v-avatar>
        </v-col>
        <v-col cols="12" md="8">
          <v-col md="4" cols="12">
            <v-text-field
              v-model="confirmation_data.receiver_account_no"
              label="Account No."
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>
          <v-col md="12" cols="12">
            <v-text-field
              readonly
              v-model="confirmation_data.receiver_full_name"
              label="Full Name"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col md="12" cols="12">
            <v-row>
              <v-col md="6" cols="12">
                <v-text-field
                  readonly
                  v-model="confirmation_data.receiver_reference_no"
                  label="Reference No."
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col md="6" cols="12">
                <v-text-field
                  readonly
                  v-model="confirmation_data.receiver_code"
                  label="Code No."
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col md="4" cols="12">
                <v-text-field
                  v-model="confirmation_data.receiver_amount"
                  label="Amount"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>

  export default {
    props: {
      confirmation_data: Object,
    },
  }
</script>
