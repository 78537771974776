<template>
  <div>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-center text-uppercase">
            Account No
          </th>
          <th class="text-center text-uppercase">
            First Name
          </th>
          <th class="text-center text-uppercase">
            Middle Name
          </th>
          <th class="text-center text-uppercase">
            Last Name
          </th>
          <th class="text-center text-uppercase">
            Acction
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="item in search_items"
          :key="item.id"
        >
          <td class="text-center">
            {{ item.account_no
            }}
          </td>
          <td class="text-center">
            {{ item.data.first_name }}
          </td>
          <td class="text-center">
            {{ item.data.middle_name }}
          </td>
          <td class="text-center">
            {{ item.data.last_name }}
          </td>
          <td class="text-center">
            <div>
              <v-icon
                class="mr-2"
                color="primary"
                @click="view_data(search_items.map(function(x) {return x.id; }).indexOf(item.id))"
              >
                {{icons.mdiEyeOutline}}
              </v-icon>
            </div>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

  </div>
</template>

<script>
  import {mdiEyeOutline} from "@mdi/js";
  import moment from "moment";

  export default {
    setup() {
      return {
        icons: {
          mdiEyeOutline,
        },
      }
    },
    props: {
      search_items: Array,
    },
    methods: {
      view_data(index) {
        this.$emit('data', {
          'id': this.search_items[index].id,
          'cropped': this.search_items[index].picture,
          'cropped2': Object.keys(this.search_items[index].data2).length === 0 ? '' : this.search_items[index].sender_picture,
          'account_no': this.search_items[index].account_no,
          'full_name': this.search_items[index].name,
          'data2': this.search_items[index].data2,
        })
      }
    },
  }
</script>
